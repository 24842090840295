import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { PrimaryButton } from '@spreetail/pegasus';
import styled from 'styled-components';
import { tablet } from '@spreetail/pegasus';
import Intro from '../components/Intro';
import WeGive from '../components/WeGive';
import Stories from '../components/Stories';
import Donate from '../components/Donate';
import theme from './theme';
import messages from './messages';
import { Helmet } from "react-helmet";
import { createGlobalStyle } from 'styled-components'

const { fonts, spacing } = theme;


export const IndexPageTemplate = ({
  image,
  title,
  heading,
  youtubeVideoId,
  intro,
  mission,
  giving,
  meals,
  facts,
  stats,
  foodbanks,
  giveText,
}) => {
  return (<>
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={heading} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://storage.googleapis.com/spreetail-cms/25aee33706d245199a7bdc61c360dd0e.jpg" />
      <meta name="twitter:image" content="https://storage.googleapis.com/spreetail-cms/8cac3e86fc7046dcaf45a4ffe9a1dd65.jpg" />
    </Helmet>
    <GlobalStyle />
    <PageStyles>
      <Intro intro={intro} videoId={youtubeVideoId} mission={mission} giveText={giveText} />
      <WeGive total={meals.numberOfMeals} giving={giving} giveText={giveText} />
      <Stories facts={facts} stats={stats} />
      <Donate
        foodbanks={foodbanks}
        donateForm={false}
      />
    </PageStyles>
  </>)
}
const DonateButton = styled(PrimaryButton)`
  width: 200px;
`;
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0px;
  }
`
const PageStyles = styled.div`
  color: #fff;
  font-family: ${fonts.body}, sans-serif;
  h2,
  h3 {
    font-family: ${fonts.headings}, sans-serif;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.4rem;
  }
  h4 {
    font-family: ${fonts.headings}, sans-serif;
    font-weight: bold;
    font-size: 1.8rem;
    margin: 0px;
  }
  @media (min-width: ${tablet}) {
    h2 {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin: ${spacing.p} 0;
    letter-spacing: 0.01em;
  }
`;

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  youtubeVideoId: PropTypes.string,
  intro: PropTypes.object,
  mission: PropTypes.object,
  giving: PropTypes.object,
  meals: PropTypes.object,
  facts: PropTypes.object,
  stats: PropTypes.object,
  foodbanks: PropTypes.object,
  giveText: PropTypes.string,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <IndexPageTemplate
      image={frontmatter.image}
      title={frontmatter.title}
      heading={frontmatter.heading}
      youtubeVideoId={frontmatter.youtubeVideoId}
      intro={frontmatter.intro}
      mission={frontmatter.mission}
      giving={frontmatter.giving}
      meals={frontmatter.meals}
      facts={frontmatter.facts}
      stats={frontmatter.stats}
      foodbanks={frontmatter.foodbanks}
      giveText={frontmatter.giveText}
    />
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        intro {
          subtitle
        }
        giveText
        mission { title, subtitle, bolded },
        giving { title, body },
        meals { numberOfMeals },
        facts { title, factOne{ amount, descriptor, subtext }, factTwo{ amount, descriptor, subtext }, factThree{ amount, descriptor, subtext } },
        stats { title, statOne{ title, subtext }, statTwo {title, subtext }, statThree { title, subtext }, statFour { title, subtext } },
        foodbanks { lincoln{ title, subtext, buttonText,link, background, img}, omaha{title, subtext, buttonText,link, background, img} },
        youtubeVideoId,
        title,
        heading,
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
