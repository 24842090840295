import React from 'react';
import styled from 'styled-components';
import { tablet, tabletInt, desktop } from '@spreetail/pegasus';
import theme from '../templates/theme';
import { responsive } from './utils';
import { MIN_WIDTH_INT, THIRD_DESKTOP_INT } from './constants';
import { Wrapper, Grid3 } from './Grid';

const { red } = theme.palette;
const { headings } = theme.fonts;

const Stats = ({ facts }) => (
  <Background>
    <Title>
      {facts.title}
    </Title>
    <Grid>
      <GridItem>
        <Amount>
          {facts.factOne.amount}
        </Amount>
        <Type>
          {facts.factOne.descriptor}
        </Type>
        {facts.factOne.subtext}
      </GridItem>
      <GridItem>
        <RedText>
          <Amount>
            {facts.factTwo.amount}
          </Amount>
        </RedText>
        <RedText>
          <Type>
            {facts.factTwo.descriptor}
          </Type>
        </RedText>
        {facts.factTwo.subtext}
      </GridItem>
      <GridItem>
        <Amount>
          {facts.factThree.amount}
        </Amount>
        <Type>
          {facts.factThree.descriptor}
        </Type>
        {facts.factThree.subtext}
      </GridItem>

    </Grid>
  </Background>
);

const Background = styled(Wrapper)`
  padding-top: 6.4rem;
  text-align: center;
  padding-bottom: 4.2rem;
  @media (min-width: ${tablet}) {
    padding-bottom: 11.4rem;
  }
  @media (min-width: ${desktop}) {
    padding-bottom: 0;
  }
`;

const Grid = styled(Grid3)`
  @media (min-width: ${tablet}) {
    grid-column-gap: 3rem;
  }
`;

const GridItem = styled.div`
  padding: ${responsive(40, MIN_WIDTH_INT)} ${responsive(30, MIN_WIDTH_INT)} 0;
  @media (min-width: ${tablet}) {
    padding: ${responsive(40, tabletInt)} ${responsive(30, tabletInt)} 0;
  }
  @media (min-width: ${desktop}) {
    padding: ${responsive(48, THIRD_DESKTOP_INT)}
      ${responsive(30, THIRD_DESKTOP_INT)};
  }
`;
const Title = styled.h3`
  margin: 0px;
`;
const RedText = styled.span`
  color: #fff;
`;

const Amount = styled.h1`
  font-family: gilroy-bold,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,sans-serif;
  font-size: 5rem;
  line-height: 1.2;
  margin-bottom: 0;
  margin-top: 0;
`;

const Type = styled.h3`
  ont-family: gilroy-bold,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  margin-bottom: 0.8rem;
  margin-top: 0;
`;

export default Stats;
