import styled from 'styled-components';
import { tablet, desktop } from '@spreetail/pegasus';
import { responsive } from './utils';
import theme from '../templates/theme';
import { Grid2 } from './Grid';

export const Card = styled.div`
  border: 1px solid #fff;
  width: 90%;
  padding: 2rem 1.2rem 2rem;
  text-align: center;
  @media (min-width: ${tablet}) {
    text-align: left;
  }
  @media (min-width: ${desktop}) {
    padding: 40px;
  }
`;

export const CardHeader = styled.div`
  padding-bottom: ${responsive(32, 461)};
`;

export const CardHeading = styled.div`
  font-family: ${theme.fonts.headings}, sans-serif;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 3rem;
`;

export const CardGrid = styled(Grid2)`
  ${Grid2} + & {
    padding-top: ${responsive(24, 460)};
  }
  @media (min-width: ${tablet}) {
    p {
      font-size: 1.4rem;
    }
  }
`;

export const CardGridHeading = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
`;
