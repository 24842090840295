import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mobileMax, tablet } from '@spreetail/pegasus';
import theme from '../templates/theme';
import { responsive } from './utils';
import { Grid2, Wrapper } from './Grid';
import { DESKTOP_MAX_INT } from './constants';

const { red } = theme.palette;

const DonateOrg = ({ name, desc, bg, src, link, buttonText }) => (
  <Background background={bg}>
    <Img src={src} alt={name} />
    <div>
      <h3>{name}</h3>
      <p>{desc}</p>
      <LinkBtn href={link} target="_blank">
        {buttonText}
      </LinkBtn>
    </div>
  </Background>
);

const imageWidth = '131px';

const Background = styled.div`
  background-image: url(${({ background }) => background});
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  padding:32px;

  @media (max-width: ${mobileMax}) {
    text-align: center;
  }
  @media (min-width: ${tablet}) {
    grid-template-columns: ${imageWidth} 1fr;
    grid-column-gap: 1.5rem;
  }

  @media (min-width: ${`${DESKTOP_MAX_INT + 1}px`}) {
    background: transparent;
  }
`;

const Img = styled.img`
  @media (max-width: ${mobileMax}) {
    margin: 32px auto 0;
  }
  width: ${imageWidth};
  height: auto;
`;

const LinkBtn = styled.a`
  display: inline-block;
  color: #fff;
  font-weight: bold;
  line-height: 1;
  padding: 12px;
  text-align: center;
  border: 2px solid #fff;
  text-decoration:none;
  text-size:14px;
  width: 160px;
  margin-top:24px;
  margin-bottom:24px;
  @media (min-width: ${tablet}) {

    margin-bottom:0;
  }
  &:hover {
    background-color: #fff;
    color: ${red};
  }
  &:focus:not(:active) {
    outline: 2px dashed #fff;
    outline-offset: 0.4rem;
  }
`;

const Donate = ({ foodbanks }) => (
  <DonateBackground>
    <Wrapper>
      <Grid2>
        <DonateOrg name={foodbanks.lincoln.title}
          desc={foodbanks.lincoln.subtext}
          bg={foodbanks.lincoln.background}
          src={foodbanks.lincoln.img}
          link={foodbanks.lincoln.link}
          buttonText={foodbanks.lincoln.buttonText}
          key={foodbanks.lincoln.name} />
        <DonateOrg name={foodbanks.omaha.title}
          desc={foodbanks.omaha.subtext}
          bg={foodbanks.omaha.background}
          src={foodbanks.omaha.img}
          link={foodbanks.omaha.link}
          buttonText={foodbanks.omaha.buttonText}
          key={foodbanks.omaha.name} />
      </Grid2>
    </Wrapper>
  </DonateBackground>
);

const DonateBackground = styled.div`

p {
  margin:0 !important;
}

h3{
  margin:16px 0 4px 0;
}
  @media (min-width: ${`${DESKTOP_MAX_INT + 1}px`}) {
    background-color: ${red};
    background-image: url(https://storage.googleapis.com/spreetail-cms/dc17e8230eb04da29fb04c7680c008d8.jpg),
      url(https://storage.googleapis.com/spreetail-cms/b03f992cce704e5196185b26c8e7664e.jpg);
    background-repeat: no-repeat, no-repeat;
    background-size: 50% auto, 50% auto;
    background-position: left, right;
    padding:80px;

    p {
      margin:0 !important;
    }

    h3{
      margin:0;
    }
  }
`;

Donate.propTypes = {
  organizations: PropTypes.array,
};

export default Donate;
