import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const YoutubeVideo = ({ id, title, playList }) => (
  <VideoWrapper>
    <EmbedContainer className="embed-container">
      <iframe
        title={title}
        src={`https://www.youtube.com/embed/${id}?modestbranding=1&loop=1&playlist=${playList ||
          id}`}
        frameBorder="0"
        allowFullScreen
      />
    </EmbedContainer>
  </VideoWrapper>
);

const VideoWrapper = styled.div`
  width: 100%;
  height: auto;
`;

const EmbedContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

YoutubeVideo.propTypes = {
  id: PropTypes.string,
  playList: PropTypes.string,
  title: PropTypes.string,
};

export default YoutubeVideo;
