import React from 'react';
import styled from 'styled-components';
import { tablet, desktop } from '@spreetail/pegasus';
import theme from '../templates/theme';
import { responsive } from './utils';
import Stats from './Stats';
import StoryTwo from './StoryTwo';
import { MIN_WIDTH_INT } from './constants';

const { purple2 } = theme.palette;

const Stories = ({ facts, stats }) => {
  return (<Background>
    <Stats facts={facts} />
    <StoryTwo stats={stats} />
  </Background>);
};

const Background = styled.div`
  background-color: ${purple2};
  background-size: ${responsive(436, MIN_WIDTH_INT)} auto;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-image: url(https://storage.googleapis.com/spreetail-cms/356feef11cbd4358ba78dfc0c12a8722.jpg);
  @media (min-width: ${tablet}) {
    background-image: url(https://storage.googleapis.com/spreetail-cms/356feef11cbd4358ba78dfc0c12a8722.jpg);
    background-size: 100% auto, ${responsive(935)} auto;
    background-repeat: no-repeat, no-repeat;
    background-position: bottom center;
  }
  @media (min-width: ${desktop}) {
    background-position: bottom center, bottom left;
  }
`;

export default Stories;
