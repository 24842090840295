import React from 'react';
import styled from 'styled-components';
import { tablet, desktop } from '@spreetail/pegasus';
import theme from '../templates/theme';
import { responsive } from './utils';
import { MIN_WIDTH_INT } from './constants';
import {
  Card,
  CardHeader,
  CardHeading,
  CardGrid,
  CardGridHeading,
} from './Card';

const { purple1 } = theme.palette;

const StoryTwo = ({ stats }) => (
  <Background>
    <CardTwoWrapper>
      <Card>
        <CardHeader>
          <CardHeading>{stats.title}</CardHeading>
          <p>
            {stats.subtitle}
          </p>
        </CardHeader>
        <CardGrid>
          <div>
            <CardGridHeading>{stats.statOne.title}</CardGridHeading>
            <p>{stats.statOne.subtext}</p>
          </div>
          <div>
            <CardGridHeading>{stats.statTwo.title}</CardGridHeading>
            <p>{stats.statTwo.subtext}</p>
          </div>
        </CardGrid>
        <CardGrid>
          <div>
            <CardGridHeading>{stats.statThree.title}</CardGridHeading>
            <p>{stats.statThree.subtext}</p>
          </div>
          <div>
            <CardGridHeading>{stats.statFour.title}</CardGridHeading>
            <p>{stats.statFour.subtext}</p>
          </div>
        </CardGrid>
      </Card>
    </CardTwoWrapper>
  </Background>
);

const Background = styled.div`
  padding-left: ${responsive(16, MIN_WIDTH_INT)};
  padding-right: ${responsive(16, MIN_WIDTH_INT)};
  padding-top: ${responsive(60, MIN_WIDTH_INT)};
  padding-bottom: ${responsive(279, MIN_WIDTH_INT)};
  @media (min-width: ${tablet}) {
    padding: 64px;
  }
`;

const CardTwoWrapper = styled.div`
  background-color: ${purple1};
  display:flex;
  padding: 2%;
  width:90%;
  margin: 0 auto;

  @media (min-width: ${tablet}) {
    width: 75%;
  }
  @media (min-width: ${desktop}) {
    margin-left: 10%;
    width: 480px;
  }
`;

export default StoryTwo;
