/*
 *
 * StrongerThanHunger constants
 *
 */

export const LOAD_STRONGER_THAN_HUNGER_DATA =
  'app/StrongerThantHunger/LOAD_STRONGER_THAN_HUNGER_DATA';
export const STRONGER_THAN_HUNGER_DATA_RECEIVED =
  'app/StrongerThantHunger/STRONGER_THAN_HUNGER_DATA_RECEIVED';
export const STRONGER_THAN_HUNGER_DATA_ERROR =
  'app/StrongerThantHunger/STRONGER_THAN_HUNGER_DATA_ERROR';

export const MAX_WIDTH = '2560px';
export const DESKTOP_MAX_INT = 1280;
export const DESKTOP_MAX = `${DESKTOP_MAX_INT}px`;
export const HALF_DESKTOP_INT = DESKTOP_MAX_INT / 2;
export const HALF_DESKTOP = `${HALF_DESKTOP_INT}px`;
export const THIRD_DESKTOP_INT = DESKTOP_MAX_INT / 3;
export const THIRD_DESKTOP = `${THIRD_DESKTOP_INT}px`;
export const GT_DESKTOP = `${DESKTOP_MAX_INT + 1}px`;
export const MIN_WIDTH_INT = 320;
export const MIN_WIDTH = `${MIN_WIDTH_INT}px`;
export const MIN_SPACING_INT = 22;
