import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { between } from 'polished';
import YoutubeVideo from './YoutubeVideo';
import {
  desktop,
  mobileMax,
  tablet,
  tabletInt,
  tabletMax,
} from '@spreetail/pegasus';
import theme from '../templates/theme';
import { responsive } from './utils';
import { DesktopLogoSVG, MobileLogoSVG } from './LogoSVG';
import Donate from './Donate';
import {
  DESKTOP_MAX,
  HALF_DESKTOP_INT,
  MAX_WIDTH,
  MIN_WIDTH_INT,
  MIN_SPACING_INT,
} from './constants';
import { Grid2, Wrapper } from './Grid';

const { gradient, orange, red } = theme.palette;

const Intro = ({ videoId, intro, mission, giveText }) => (
  <Background>
    <Wrapper>
      <GridTop showVideoBg={videoId}>
        <GridItemLeft>
          <Logo></Logo>
          <IntroHeading>
            <DesktopSVGContainer>
              <DesktopLogoSVG
                color={orange}
                title="Stronger Than Hunger"
              />
            </DesktopSVGContainer>
            <MobileSVGContainer>
              <MobileLogoSVG
                color={orange}
                title="Stronger Than Hunger"
              />
            </MobileSVGContainer>
          </IntroHeading>
          <MainHeading>
            {intro.title}
          </MainHeading>
          <BodyText>
            {intro.subtitle}
          </BodyText>
          <LinkBtn href="https://donate.foodbankheartland.org/Default.aspx?tsid=12156" target="_blank">
            {giveText}
          </LinkBtn>
          {videoId && videoId.length && videoId.length > 2 && (
            <VideoWrapper>
              <YoutubeVideo title="Stronger Than Hunger" id={videoId} />
            </VideoWrapper>
          )}
        </GridItemLeft>
      </GridTop>
      <GridBottom>
        <GridItemLeft>

        </GridItemLeft>
        <Floating>
          <MissionHeading>
            {mission.title}
          </MissionHeading>
          <BodyText>
            {mission.subtitle}
            <Bolded>{` ${mission.bolded}`}</Bolded>
          </BodyText>
        </Floating>
      </GridBottom>
    </Wrapper>
  </Background>
);

const Logo = styled.div`
background-image: url(https://storage.googleapis.com/spreetail-cms/2d108c8151654ac18c4ebafaf102dd15.png);

`;

const LinkBtn = styled.a`
  display: inline-block;
  color: #fff;
  font-weight: bold;
  line-height: 1;
  padding: 12px;
  text-align: center;
  border: 2px solid #fff;
  text-decoration:none;
  font-size: 14px;
  width: 160px;

  @media (min-width: ${tablet}) {
    font-size: 14px;
  }
  &:hover {
    background-color: #fff;
    color: ${red};
  }
  &:focus:not(:active) {
    outline: 2px dashed #fff;
    outline-offset: 0.4rem;
  }
`;


const MobileSVGContainer = styled.div`
  display: block;
  @media (min-width: ${tablet}){
    display: none;
  }
`;

const DesktopSVGContainer = styled.div`
  display: none;
  @media (min-width: ${tablet}){
    display: block;
  }
`;


const Bolded = styled.span`
  font-weight: bold;
`;

const BoldedColor = styled.span`
  font-size: 2.4rem;
`;

const Background = styled.div`
  background-color: ${gradient.purple};
  background-image: url("data:image/svg+xml,%3Csvg width='693' height='823' viewBox='0 0 693 823' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg style='mix-blend-mode:overlay' opacity='0.10'%3E%3Cpath d='M693 307.715L0 0V227.321L389.01 410.98L0 595.679V823L693 515.284V307.715Z' fill='%23EE5224'/%3E%3C/g%3E%3C/svg%3E%0A"),
    linear-gradient(
      141.74deg,
      ${gradient.orange} -0.23%,
      ${gradient.red} 17.49%,
      ${gradient.purple} 53.18%
    );
  background-repeat: no-repeat, no-repeat;
  background-size: ${responsive(534, MIN_WIDTH_INT)} auto, 100%;
  background-position: top -30% right 118%, top left;
  @media (min-width: ${tablet}) {
    padding: ${responsive(60)} 0;
    background-image: url("data:image/svg+xml,%3Csvg width='693' height='823' viewBox='0 0 693 823' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg style='mix-blend-mode:overlay' opacity='0.10'%3E%3Cpath d='M693 307.715L0 0V227.321L389.01 410.98L0 595.679V823L693 515.284V307.715Z' fill='%23EE5224'/%3E%3C/g%3E%3C/svg%3E%0A"),
      url(https://storage.googleapis.com/spreetail-cms/652237fc8d364e17aaaa6f5f2eee0705.png),
      linear-gradient(
        141.74deg,
        ${gradient.orange} -0.23%,
        ${gradient.red} 17.49%,
        ${gradient.purple} 53.18%
      );
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: ${responsive(693, tabletInt)} auto,
      ${responsive(843, tabletInt)} auto, 100%;
    background-position: top -15% right -417%, top 0 right 380%, top left;
  }
  @media (min-width: ${desktop}) {
    background-size: ${responsive(693)} auto, ${responsive(955)} auto, 100%;
    background-position: top -30% right -27.5%, top 90% right -14.75%, top left;
  }
  @media (min-width: ${DESKTOP_MAX}) {
    background-size: ${between('693px', '1127px', DESKTOP_MAX, MAX_WIDTH)} auto,
      ${between('955px', '1554px', DESKTOP_MAX, MAX_WIDTH)} auto, 100%;
    background-position: top
        ${between('-104px', '-62px', DESKTOP_MAX, MAX_WIDTH)} right
        ${between('-168px', '-104px', DESKTOP_MAX, MAX_WIDTH)},
      top ${between('-144px', '-88px', DESKTOP_MAX, MAX_WIDTH)} right
        ${between('-66px', '-34px', DESKTOP_MAX, MAX_WIDTH)},
      top left;
  }
`;

const GridTop = styled(Grid2)`
  background-repeat: no-repeat;
  background-position: center bottom -4%;
  background-size: ${responsive(295, MIN_WIDTH_INT)} auto;
  padding-top: ${responsive(57, MIN_WIDTH_INT)};
  padding-bottom: ${responsive(10, MIN_WIDTH_INT)};
  @media (min-width: ${tablet}) {
    background-position: left ${responsive(15, tabletInt)} bottom;
    background-size: ${responsive(500, tabletInt)} auto;
    padding-top: 0;
    padding-bottom: 0;
  }
  @media (min-width: ${tablet}) and (max-width: ${tabletMax}) {
  }
  @media (min-width: ${desktop}) {
    background-position: left ${responsive(125, HALF_DESKTOP_INT)} bottom 0;
    background-size: ${responsive(500)} auto;
  }
`;

const GridBottom = styled(Grid2)`
  padding-top: ${responsive(28)};
`;

const GridItem = styled.div`
  @media (max-width: ${mobileMax}) {
    padding: 0 ${responsive(MIN_SPACING_INT, MIN_WIDTH_INT)}
      ${responsive(MIN_SPACING_INT * 2, MIN_WIDTH_INT)};
  }
`;

const GridItemLeft = styled(GridItem)`
  @media (min-width: ${tablet}) and (max-width: ${tabletMax}) {
    padding-left: ${responsive(MIN_SPACING_INT, tabletInt)};
    padding-right: ${responsive(284, tabletInt)};
    padding-bottom: ${responsive(70, tabletInt)};
  }
  @media (min-width: ${desktop}) {
    padding-left: ${responsive(163, HALF_DESKTOP_INT)};
    padding-right: ${responsive(15, HALF_DESKTOP_INT)};
  }
`;
const GridItemRight = styled(GridItem)`
  @media (min-width: ${tablet}) and (max-width: ${tabletMax}) {
    padding-left: ${responsive(385, tabletInt)};
    padding-right: ${responsive(MIN_SPACING_INT * 2, tabletInt)};
  }
  @media (min-width: ${desktop}) {
    padding-left: ${responsive(108, HALF_DESKTOP_INT)};
    padding-right: ${responsive(96, HALF_DESKTOP_INT)};
  }
`;

const BodyText = styled.p`
  padding-bottom: 1.2rem;
`;

const VideoWrapper = styled.div`
  height: auto;
  padding-top: 2rem;
  @media (min-width: ${tablet}) {
    padding-bottom: ${responsive(70, tabletInt)};
  }
  @media (min-width: ${desktop}) {
    max-width: 500px;
    padding-bottom: ${responsive(150, HALF_DESKTOP_INT)};
  }
`;

const IntroHeading = styled.h1`
  margin-bottom: 2.3rem;
`;

const RedText = styled.span`
  color: ${red};
`;

const MissionHeading = styled.h3`
  margin: 0px;
`;

const MainHeading = styled.h4``;
const Floating = styled(GridItemRight)`
  margin-top: -60px;
  @media (min-width: ${desktop}) {
    margin-top: -180px;
  }
`;
Intro.propTypes = {
  videoId: PropTypes.string,
};

export default Intro;
