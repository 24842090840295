const palette = {
  gradient: {
    orange: '#ee5224',
    red: '#a92426',
    purple: '#421a3a',
  },
  red: '#D93912',
  orange: '#EE5224',
  purple1: '#160824',
  purple2: '#26103c',
};

const fonts = {
  body: 'Lato',
  headings: 'gilroy-bold, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
};

const spacing = {
  p: '1.2rem',
};

export default {
  palette,
  fonts,
  spacing,
};
