/*
 * StrongerThanHunger Messages
 *
 * This contains all the text for the StrongerThanHunger container.
 */


export const scope = 'app.container.StrongerThanHunger';

export default {
  intro: {
    heading: {
      id: `${scope}.intro.heading`,
      defaultMessage: 'Stronger Than Hunger',
    },
    body1: {
      id: `${scope}.intro.body1`,
      defaultMessage:
        'More than 82,000 children across Nebraska struggle with hunger. Spreetail and the University of Nebraska at Lincoln have teamed up to end this hardship.',
    },
    insecureHeading: {
      id: `${scope}.intro.insecureHeading`,
      defaultMessage: '{amount} Children in Nebraska Are Food Insecure.',
    },
    heading: {
      id: `${scope}.intro.heading`,
      defaultMessage: 'Our Mission',
    },
    body: {
      id: `${scope}.intro.body`,
      defaultMessage:
        'Young, old, tall, or small — Stronger Than Hunger is a rallying call to unify the state and support one another in a fight to end childhood hunger. Together, we can help our children grow. Together, Nebraska is stronger than hunger.',
    },
  },
  weGive: {
    heading: {
      id: `${scope}.weGive.heading`,
      defaultMessage: 'Every Game, We Give',
    },
    body: {
      id: `${scope}.weGive.body`,
      defaultMessage:
        'Young, old, tall, or small — Stronger Than Hunger is a rallying call to unify the state and support one another in a fight to end childhood hunger. Together, we can help our children grow. Together, Nebraska is stronger than hunger.',
    },
    mealsGiven: {
      id: `${scope}.weGive.mealsGiven`,
      defaultMessage: 'Meals {given}',
    },
  },
  featQuote: {
    heading: {
      id: `${scope}.featQuote.heading`,
      defaultMessage: 'I Reach For The Stars',
    },
    future: {
      id: `${scope}.featQuote.future`,
      defaultMessage: 'Future Astronaut',
    },
    profile: {
      id: `${scope}.featQuote.profile`,
      defaultMessage: '- Joel Hudson, 8 Lincoln',
    },
  },
  stories: {
    stats: {
      heading: {
        id: `${scope}.stories.stats.heading`,
        defaultMessage: 'Did you know?',
      },
      meals: {
        amount: {
          id: `${scope}.stories.stats.meals.amount`,
          defaultMessage: '6k',
        },
        type: {
          id: `${scope}.stories.stats.meals.type`,
          defaultMessage: 'meals',
        },
        context: {
          id: `${scope}.stories.stats.meals.context`,
          defaultMessage: 'Are given for every 300yards gained.',
        },
      },
      fans: {
        amount: {
          id: `${scope}.stories.stats.fans.amount`,
          defaultMessage: '85k',
        },
        type: {
          id: `${scope}.stories.stats.fans.type`,
          defaultMessage: 'cheering fans',
        },
        context: {
          id: `${scope}.stories.stats.fans.context`,
          defaultMessage: 'In Memorial Stadium at a sold out game.',
        },
      },
      children: {
        amount: {
          id: `${scope}.stories.stats.children.amount`,
          defaultMessage: '0',
        },
        type: {
          id: `${scope}.stories.stats.children.type`,
          defaultMessage: 'hungry children',
        },
        context: {
          id: `${scope}.stories.stats.children.context`,
          defaultMessage: 'If everyone in Memorial Stadium gave only $1.',
        },
      },
    },
  },
  donate: {
    btnText: {
      id: `${scope}.btnText`,
      defaultMessage: 'Donate',
    },
    foodbankLincoln: {
      name: {
        id: `${scope}.foodbankLincoln.name`,
        defaultMessage: 'FoodBank of Lincoln',
      },
      desc: {
        id: `${scope}.foodbankLincoln.desc`,
        defaultMessage: 'The Food Bank of Lincoln is Feeding America.',
      },
      link: {
        id: `${scope}.foodbankLincoln.link`,
        defaultMessage: 'https://www.lincolnfoodbank.org/donate/',
      },
    },
    foodbankHeartland: {
      name: {
        id: `${scope}.foodbankHeartland.name`,
        defaultMessage: 'FoodBank for the Heartland',
      },
      desc: {
        id: `${scope}.foodbankHeartland.desc`,
        defaultMessage: 'Founded in 1981 in Omaha, Nebraska.',
      },
      link: {
        id: `${scope}.foodbankHeartland.link`,
        defaultMessage:
          'https://donate.foodbankheartland.org/default.aspx?tsid=9057',
      },
    },
  },
};
