import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { between } from 'polished';
import {
  tabletInt,
  desktop,
  mobileMax,
  tablet,
  tabletMax,
} from '@spreetail/pegasus';
import theme from '../templates/theme';
import { responsive } from './utils';
import { Grid2, Wrapper } from './Grid';
import {
  GT_DESKTOP,
  MIN_WIDTH_INT,
  MIN_SPACING_INT,
  HALF_DESKTOP_INT,
  MAX_WIDTH,
} from './constants';

const { headings } = theme.fonts;
const { purple2, purple1, red } = theme.palette;

const WeGive = ({ total, giving, giveText }) => (
  <Background>
    <Wrapper>
      <Grid2>
        <MissionWrapper>
          <Title>
            {giving.title}
          </Title>
          <p>
            {giving.body}
          </p>
          <LinkBtn href="https://donate.foodbankheartland.org/Default.aspx?tsid=12156" target="_blank">
            {giveText}
          </LinkBtn>
        </MissionWrapper>
        <MealsGivenWrapper>
          <WeGiveInnerWrapper>
            <MealsGiven>
              Meals
              <RedText> Given</RedText>
            </MealsGiven>
            <Total>
              {total}
            </Total>
          </WeGiveInnerWrapper>
        </MealsGivenWrapper>
      </Grid2>
    </Wrapper>
    <FeaturedQuote>
      <QuoteWrapper>
        <Quote>
          <p>
          </p>
          <footer>
            <cite>
              <Profile>
              </Profile>
            </cite>
          </footer>
        </Quote>
      </QuoteWrapper>
    </FeaturedQuote>
  </Background>
);

const Background = styled.div`
  background: ${purple1};
        position: relative;
  > * {
      position: relative;
z-index: 2;
}
  @media (min-width: ${GT_DESKTOP}) {
    &: :after {
      content: '';
height: 100%;
width: 50%;
position: absolute;
top: 0;
right: 0;
background: ${purple2};
}
}
`;
const Bolded = styled.span`
  font-weight: bold;
`;
const LinkBtn = styled.a`
  display: inline-block;
  color: #fff;
  font-weight: bold;
  line-height: 1;
  padding: 12px;
  text-align: center;
  margin-right: 16px;
  border: 2px solid #fff;
  text-decoration:none;
  font-size:14px;
  width: 160px;
  @media (min-width: ${tablet}) {
    font-size: 14px;
    float: left;
  }
  &:hover {
    background-color: #fff;
    color: ${red};
  }
  &:focus:not(:active) {
    outline: 2px dashed #fff;
    outline-offset: 0.4rem;
  }
`;
const WeGiveWrapper = styled.div`
  padding: ${responsive(64, MIN_WIDTH_INT)}
    ${responsive(MIN_SPACING_INT, MIN_WIDTH_INT)};
`;

const WeGiveInnerWrapper = styled.div`
  padding-left: 0px;
  @media (min-width: ${desktop}) {
    padding-left: 24px;
  }
`;

const Title = styled.h3`
  font-weight: bold;
  margin: 0px;
`;

const MissionWrapper = styled(WeGiveWrapper)`
  @media (min-width: ${desktop}) {
      padding: ${responsive(132)} ${responsive(180)} ${responsive(122)}
    ${responsive(65)};

}
  @media (min-width: ${GT_DESKTOP}) {
      padding-top: ${between('110px', '140px', GT_DESKTOP, MAX_WIDTH)};
    padding-bottom: ${between('100px', '130px', GT_DESKTOP, MAX_WIDTH)};
  }
`;

const GridItem = styled.div`
  @media (max-width: ${mobileMax}) {
      padding: 0 ${responsive(MIN_SPACING_INT, MIN_WIDTH_INT)}
    ${responsive(MIN_SPACING_INT * 2, MIN_WIDTH_INT)};
}
`;

const MealsGivenWrapper = styled(WeGiveWrapper)`
  padding: ${responsive(64, MIN_WIDTH_INT)}
    ${responsive(MIN_SPACING_INT, MIN_WIDTH_INT)};
  background: ${purple2};
        text-align: center;
  font-family: ${headings}, sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      `;

const MealsGiven = styled.h2`
  font-family: ${headings};
        font-size: 2.2rem;
  @media (min-width: ${tablet}) {
      font-size: 3.4rem;
line-height: 2.7rem;
  margin-bottom: 0;
}
`;

const Total = styled.h1`
font-family: ${headings}, sans-serif;
font-size: 4rem;
line-height: 4.5rem;
margin-bottom: ${responsive(10, MIN_WIDTH_INT)};
margin-top: 0;
@media (min-width: ${desktop}) {
      margin-bottom: 0;
      font-size: 7rem;
      line-height: 8.5rem;
}
`;

const RedText = styled.span`
  color: ${red};
      `;

const FeaturedQuote = styled.div`
  background-image: url(https://storage.googleapis.com/spreetail-cms/ff1701b530bf4f4b831fd91ea96bca21.jpg);
  background-size: cover;
  background-position: left ${responsive(20, MIN_WIDTH_INT)} center;
  position: relative;
  background-repeat: no-repeat;
  @media (min-width: ${tablet}) {
    background-image: url(https://storage.googleapis.com/spreetail-cms/8d48cfeda47c44b68a5a42c82d141e69.jpg);
    background-position: left ${responsive(340, tabletInt)} center;

}
  @media (min-width: ${desktop}) {
      background-position: center;
}
`;

const QuoteWrapper = styled(Wrapper)`
  padding: 0 ${responsive(64)};
      `;

const Quote = styled.blockquote`
  margin: 0px;
  width: 96%;
  background-image: url(https://storage.googleapis.com/spreetail-cms/79379aa499df4f3683b5b91f511f244b.png);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 15rem;
  background-position: top ${responsive(20, MIN_WIDTH_INT - MIN_SPACING_INT)}
    center;
  padding-top: ${responsive(363, MIN_WIDTH_INT - MIN_SPACING_INT)};
  padding-bottom: ${responsive(
  MIN_SPACING_INT,
  MIN_WIDTH_INT - MIN_SPACING_INT,
)};
            text-align: right;
  p {
      font-size: 0;
height: 0;
margin: 0 !important;
visibility: hidden;
}
  cite {
      font-style: normal;
}

  @media (min-width: ${tablet}) {
    height: auto;
    padding-top: ${responsive(356, tabletInt - 64)};
    padding-bottom: ${responsive(25, tabletInt - 64)};
    background-size: ${responsive(496, tabletInt - 64)};
    background-position: top ${responsive(106, tabletInt - 64)} right;
  }
  @media (min-width: ${desktop}) {
    padding-top: ${responsive(52)};
    padding-bottom: ${responsive(35)};
    background-size: ${responsive(633)};
    background-position: top ${responsive(624)} right;
    margin-left: 20px;

    footer {
      margin-top: ${responsive(297)};
}
}
  @media (min-width: ${GT_DESKTOP}) {
      padding-top: ${between('52px', '125px', GT_DESKTOP, MAX_WIDTH)};
    padding-bottom: ${between('35px', '84px', GT_DESKTOP, MAX_WIDTH)};
  }
`;

const Profile = styled.div`
  font-family: ${theme.fonts.headings}, sans-serif;
        font-size: 2rem;
      `;

WeGive.propTypes = {
  total: PropTypes.number,
};

export default WeGive;
