import styled from 'styled-components';
import { desktop } from '@spreetail/pegasus';
import { DESKTOP_MAX_INT } from './constants';

export const Wrapper = styled.div`
  max-width: ${`${DESKTOP_MAX_INT}px`};
  margin: 0 auto;
`;

export const Grid2 = styled.div`
  display: grid;
  @media (min-width: ${desktop}) {
    grid-template-columns: 50% 50%;
    column-gap: 12px;
  }
`;

export const Grid3 = styled.div`
  display: grid;
  @media (min-width: ${desktop}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
